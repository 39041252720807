.sectionHeadingText {
    color: #4C6249;
    font-size: var(--section-heading-font-size);
    margin-bottom: 3px;
    margin-top: 17px;
}

.whyWithUsSection {
    /* border-bottom: 2px solid black; */
    display: flex;
    flex-direction: column;
    background-color: #E8B7B7;
    padding: 0px 150px;
    padding-bottom: 50px;
}

@media only screen and (max-width: 1000px) {
    .whyWithUsSection {
        padding: 0px;
        padding-bottom: 50px;
    }

    .whyWithUsContainer {
        align-items: center
    }

    .whyWithUsBlockWithPhoto {
        padding: 15px !important;
    }
}

@media only screen and (max-width: 740px) {
    .whyWithUsBlockWithPhoto {
        flex-direction: column !important;
        justify-content: center !important;
    }

    .whyWithUsLeftAlign {
        flex-direction: column-reverse !important;
    }

    .whyWithUsBlockWithPhoto img {
        margin-top: 10px !important;
    }

    .whyWithUsBlock {
        width: 70vw !important;
    }

    .whyWithUsContainer {
        align-items: center;
    }
}


.whyWithUsCallToAction {
    background-color: #FFEAEA;
    width: 150px;
    color: #4C6249;
    font-weight: bold;
    align-self: center;
    border-radius: 10px;
    margin-bottom: 20px;
    /* margin-top: 160px; */
    padding: 10px 15px;
    cursor: pointer;
}

.whyWithUsContainer {
    display: flex;
    flex-direction: column;
}

.whyWithUsBlock {
    width: 30vw;
    /* border: 3px solid #FFEAEA; */
    padding: 0px 25px;
    /* background-color: #4C6249; */
    color: var(--verde);
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    text-align: justify;
}

.whyWithUsBlockIcon {
    font-size: 40px;
    margin-bottom: 10px;
}

.whyWithUsBlockWithPhoto {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    /* justify-content: center; */
    padding: 20px 0px;
    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    margin: 15px 0px;
    border: 2px solid var(--rosa-claro);
}

.whyWithUsBlockWithPhoto img {
    /* align-self: flex-end; */
    /* margin: 0px 55px; */
    border: 5px solid #FFEAEA;
    /* border-bottom: 60px solid var(--rosa-claro); */
    height: 180px;
}

.whyWithUsRightAlign {
    /* align-self: center; */
    /* margin-left: 100px; */
}

.whyWithUsLeftAlign {
    /* align-self: flex-end; */
    /* margin-right: 100px; */
}

.imgWhyWithUs1 {
    /* width: 350px; */
    height: 250px;
    object-fit: contain;
}

.container {
    position: relative;
    width: 350px;
    z-index: 0;
}

.image {
    /* width: 350px; */
    /* Adjust as needed */
    height: 450px;
    /* Adjust as needed */
}

.overlay {
    position: absolute;
    bottom: 45%;
    left: 25%;
    width: 50%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    text-align: center;
}

.about-Olga-border-left {
    border-left: 1px solid;
}

.about-Olga-border-right {
    border-right: 1px solid;
}