.expandedStandardAndPremiumPackage {
    text-align: left;
    background-color: --var("rosa");
    padding: 5px 10px
}


div .standardAndPremiumPackageHeading:first-child {
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    margin-top: -25px !important;
    /* padding-bottom: 5px; */
    padding-top: 20px;
}

.standardAndPremiumPackageHeading {
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    padding-top: 10px;
    /* padding-bottom: 5px; */
    font-size: var(--package-item-heading-font-size);
}

.standardAndPremiumPackageHeadingIcon {
    font-size: var(--package-item-heading-icon-size);
    margin-bottom: -2px;
    margin-right: 5px;
}

.standardAndPremiumPackagePlusIcon {
    font-size: 50px;
    color: white;
    margin-top: 10px;
    margin-bottom: -40px;
}

.plusIconStandardAndPremiumContainer {
    display: flex;
    justify-content: center;
}