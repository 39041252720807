.containerForBlogArticles {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20px;

}

.whole-blog-page-container {
    padding-top: 10px;
    background-color: white;
    overflow: hidden;
}

.blog-page-heading {
    color: white;
    font-size: var(--section-heading-font-size);
    padding-bottom: 40px;
    padding-top: 40px;
    margin: 0px;
    z-index: 2;
    display: flex;
    align-items: center;
    width: 300px;
}

.blog-page-heading .blog-title {
    width: 250px;
    font-size: 35px;
}

.blog-title-tagline {
    font-size: 15px;
    max-width: 350px;
    z-index: 2;
    color: white;
}

.blog-page-heading-container {
    position: relative;
    width: 100%;
    /* height: 300px; */
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blog-page-heading-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../blogPosts/images/12-reasons-why-argentina-for-giving-birth.jpg");
    background-size: cover;
    background-position: center;
    filter: blur(4px) brightness(80%);
    /* Adjust the blur intensity here */
    z-index: 1;
}


.blog-tags-container {
    display: 'flex';
    flex-wrap: 'wrap';
    margin-bottom: 5px;
    margin-top: 10px;
}

@media only screen and (max-width: 965px) {
    .blog-page-heading-container {
        flex-direction: column;
        padding-bottom: 20px;
    }

    .blog-page-heading {
        padding-bottom: 10px;
    }
}