.contact-me-section {
    /* border-bottom: 2px solid black; */
    display: flex;
    flex-direction: column;
    /* padding-bottom: 360px; */
    background-color: var(--rosa);
    align-items: center;
}

.contact-me-section-heading-text {
    color: #4C6249;
    font-size: var(--section-heading-font-size);
}

.contact-me-social-media-container {
    margin-bottom: 50px;
    display: flex;
}

.contact-me-social-icon {
    height: 70px;
    width: 100px;
}

.contact-me-section-text {
    font-size: 18px;
    margin-bottom: 50px;
    padding: 0px 20px;
    /* width: 70vw; */
    color: white;
    font-weight: bold;
}

.contact-social-button-with-pulse-animation {
    animation: contact-social-button-pulse 2s infinite;
}

@keyframes contact-social-button-pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}