.slide-in-left {
    /* transform: translateX(0); */
    opacity: 1;
    transition: transform var(--anim-slide-duration) ease-out, opacity var(--anim-slide-duration) ease-out;
}

.slide-out-left {
    transform: translateX(var(--translate-x-y-minus-value));
    opacity: var(--anim-initial-opacity);
    transition: transform var(--anim-slide-duration) ease-out, opacity var(--anim-slide-duration) ease-out;
}

.slide-in-right {
    transform: translateX(0);
    opacity: 1;
    transition: transform var(--anim-slide-duration) ease-out, opacity var(--anim-slide-duration) ease-out;
}

.slide-out-right {
    transform: translateX(var(--translate-x-y-plus-value));
    opacity: var(--anim-initial-opacity);
    transition: transform var(--anim-slide-duration) ease-out, opacity var(--anim-slide-duration) ease-out;
}

/* Slide from Top */
.slide-in-up {
    transform: translateY(0);
    opacity: 1;
    transition: transform var(--anim-slide-duration) ease-out, opacity var(--anim-slide-duration) ease-out;
}

.slide-out-up {
    transform: translateY(var(--translate-x-y-minus-value));
    opacity: var(--anim-initial-opacity);
    transition: transform var(--anim-slide-duration) ease-out, opacity var(--anim-slide-duration) ease-out;
}

/* Slide from Bottom */
.slide-in-down {
    transform: translateY(0);
    opacity: 1;
    transition: transform var(--anim-slide-duration) ease-out, opacity var(--anim-slide-duration) ease-out;
}

.slide-out-down {
    transform: translateY(var(--translate-x-y-plus-value));
    opacity: var(--anim-initial-opacity);
    transition: transform var(--anim-slide-duration) ease-out, opacity var(--anim-slide-duration) ease-out;
}

.slide-no-animation {
    transition: none !important;
    opacity: 1 !important;
    transform: translate(0, 0) !important;
}