.ourClientsSection {
    /* border-bottom: 2px solid black; */
    display: flex;
    flex-direction: column;
    background-color: #FFEAEA;
}

.ourClientSectionHeading {
    font-size: var(--section-heading-font-size);
    color: var(--rosa)
}