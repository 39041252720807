.reviewsFromClientsSection {
    /* border-bottom: 2px solid black; */
    display: flex;
    flex-direction: column;
    background-color: var(--rosa);
}

.reviewsFromClientsSectionHeading {
    font-size: var(--section-heading-font-size);
    color: var(--verde)
}