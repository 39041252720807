img.review-from-client-image {
    height: 320px;
    cursor: pointer;
}

.react-multi-carousel-track {
    margin-bottom: 50px;
}

.react-multiple-carousel__arrow {
    z-index: 4;
}