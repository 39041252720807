.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}

.modal-content {
    position: relative;
    background-color: white;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

}

.modal-content img {
    /* max-width: 100%;
    max-height: 100%; */
    height: 500px;
    /* max-height: 500px;
    max-width: 90%; */
}

.close-btn {
    /* margin-top: 10px; */
    /* padding: 5px 10px; */
    /* background-color: #ddd; */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    top: -30px;
    right: -30px;
    /* width: 50px; */
    display: flex;
    color: var(--rosa);
    font-size: 40px;
}