.technical-footer-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    background-color: var(--rosa-claro);
}

.build-id {
    position: absolute;
    bottom: 5px;
    right: 10px;
    font-size: 11px;
}

.hojaroja-logo {
    width: 17px;
    color: red;
    margin: 10px;
}

.hojaroja-logo-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
    align-self: center;
}

.hojaroja-logo-text {
    width: 44px;
    color: red
}

.developed-by-text {
    font-size: 11px;
}

.text-about-site-container {
    display: flex;
    flex-direction: column;
    font-size: 11px;
    padding: 0px 20px;
    padding-top: 20px;
    align-self: center;
    margin-bottom: 30px;
}

@media only screen and (max-width: 830px) {
    .text-about-site-container {
        text-align: left;
        padding-right: 130px;
        align-items: flex-start;
    }

    .hojaroja-logo-container {
        margin-left: 20px;
        align-self: flex-start;
    }
}