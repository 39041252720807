.ourPackagesSection {
    /* border-bottom: 2px solid black; */
    display: flex;
    flex-direction: column;
    background-color: white;
}

.packageSectionHeading {
    font-size: var(--section-heading-font-size);
    color: var(--verde);
    padding: 0px 20px;
}

.accompanyingCallToAction {
    background-color: #E8B7B7;
    width: 150px;
    color: white;
    font-weight: bold;
    align-self: center;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 160px;
    padding: 10px 10px;
    cursor: pointer;
}

.packagesContainer {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

@media only screen and (max-width: 1200px) {
    .packagesContainer {
        /* flex-direction: column; */
    }
}