.packageOverview {
    width: 300px;
    margin-bottom: 20px;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    align-self: flex-start;
    /* padding: 10px; */
    /* transition: max-height 0.3s ease-out; */
    /* overflow: hidden; */
    border-radius: 20px;
}

.packageNameHeader {
    font-weight: bold;
    display: flex;
    align-items: center;
    padding: 10px 10px;
    justify-content: center;
    font-size: 16px;
    border-radius: 20px 20px 0px 0px;
}

.packageHeaderIcon {
    margin-left: 5px;
}

/* START mini just birth */
.container-mini-just-birth-package {
    border: 2px solid var(--verde)
}

.icon-mini-just-birth {
    color: var(--rosa);
}

.mini-just-birth {
    color: var(--verde);
    background-color: var(--rosa-claro);
}

.mini-just-birthToggleDetails {
    background-color: #4C6249;
    color: var(--rosa-claro);
    font-weight: bold;
}

.mini-just-birth-package-logo-filtered-color {
    filter: brightness(0) saturate(100%) invert(35%) sepia(13%) saturate(822%) hue-rotate(66deg) brightness(95%) contrast(86%);
    height: 110px !important;
}

.mini-just-birth-package-price {
    color: #4C6249;
    border-color: #4C6249;
}

/* END mini just birth */

/* START mini birth before */
.container-mini-birth-before-package {
    border: 2px solid var(--rosa-claro)
}

.icon-mini-birth-before {
    color: var(--verde);
}

.mini-birth-before {
    color: white;
    background-color: var(--rosa);
}

.mini-birth-beforeToggleDetails {
    background-color: var(--rosa);
    color: var(--verde);
    font-weight: bold;
}

.mini-birth-before-package-logo-filtered-color {
    filter: brightness(0) saturate(100%) invert(85%) sepia(5%) saturate(2351%) hue-rotate(313deg) brightness(95%) contrast(92%);
    height: 110px !important;
}

.mini-birth-before-package-price {
    color: #4C6249;
    border-color: #4C6249;
}

/* END mini birth before */

/* START mini birth after */
.container-mini-birth-after-package {
    border: 2px solid var(--rosa);
}

.icon-mini-birth-after {
    color: white;
}

.mini-birth-after {
    color: var(--rosa);
    background-color: var(--verde);
}

.mini-birth-afterToggleDetails {
    background-color: var(--rosa-claro);
    color: var(--verde);
    font-weight: bold;
}

.mini-birth-after-package-logo-filtered-color {
    filter: brightness(0) saturate(100%) invert(0%) sepia(90%) saturate(7500%) hue-rotate(65deg) brightness(103%) contrast(109%);
    height: 110px !important;
}

.mini-birth-after-package-price {
    color: #4C6249;
    border-color: #4C6249;
}

/* END mini birth after */

/* START full */
.container-full-package {
    border: 2px solid var(--verde);
}

.icon-full {
    color: #f6b40e;
}

.full {
    color: #ffffff;
    background-color: #74acdf;
}

.fullToggleDetails {
    background-color: #f6b40e;
    color: #ffffff;
    font-weight: bold;
}

.full-logo-filtered-color {
    filter: brightness(0) saturate(100%) invert(70%) sepia(14%) saturate(1415%) hue-rotate(174deg) brightness(92%) contrast(90%);
}

.full-package-price {
    color: #4C6249;
    border-color: #4C6249;
}

/* END full */


.container-already-here-package {
    /* background-color: #FFEAEA; */
    border: 2px solid var(--rosa)
}

.container-standard-package {
    background-color: #FFEAEA;
}


.container-premium-package {
    background-color: #4C6249;
}

.container-vip-package {
    background-color: #E8B7B7;
}

.standard {
    background-color: #E8B7B7;
    color: white;
}

img.packageImage {
    height: 150px;
    object-fit: contain;
    margin-bottom: 10px;
}

.standardPackageShortDescription {
    /* font-weight: bold; */
    color: black;
}

.premiumPackageShortDescription {
    /* font-weight: bold; */
    color: #FFEAEA;
}

.vipPackageShortDescription {

    /* font-weight: bold; */
    color: white;
}

.package-short-description {
    padding: 15px;
    font-size: 14px;
}

.premium {
    background-color: #E8B7B7;
    color: white;
}

.vip {
    background-color: #4C6249;
    color: white;
}

.already-here {
    /* border-bottom: 2px solid var(--rosa); */
    color: var(--verde);
    background-color: var(--rosa-claro);
}

.icon-standard {
    color: white;
}

.icon-premium {
    color: lightgrey;
}

.icon-vip {
    color: yellow;
}

.icon-already-here {
    color: var(--rosa)
}


.toggleDetails {
    /* background-color: #f0f0f0; */
    /* border: 1px solid #ccc; */
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
    margin-bottom: 10px;
    font-size: 16px;
}

.already-hereToggleDetails {
    background-color: #4C6249;
    color: white;
    font-weight: bold;
}

.standardToggleDetails {
    background-color: #E8B7B7;
    color: white;
    font-weight: bold;
}

.premiumToggleDetails {
    background-color: white;
    color: #E8B7B7;
    font-weight: bold;
}

.vipToggleDetails {
    background-color: #4C6249;
    color: #FFEAEA;
    font-weight: bold;
}


.details {
    /* max-height: 0; */
    margin-top: 5px;
    overflow: hidden;
    transition: height 0.6s ease-in;
    /* background-color: #4C6249; */
}

.details.open {
    height: auto;
    /* margin-top: 0px; */
}

.details.closed {
    height: 0;
    /* margin-top: 5px; */
}

.packagePrice {
    margin-top: 15px;
    font-size: 20px;
    font-weight: 500;
    border-top: 2px solid;
    padding: 10px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.already-here-package-price {
    /* background-color: var(--rosa-claro); */
    color: var(--verde);
    border-bottom-left-radius: 19px;
    border-bottom-right-radius: 19px;
}

.standard-package-price {
    color: #4C6249;
    border-color: #4C6249;
}

.premium-package-price {
    color: #FFEAEA;
    border-color: #FFEAEA;
}

.vip-package-price {
    color: white;
    border-color: white;
}

.already-here-package-logo-filtered-color {
    filter: brightness(0) saturate(100%) invert(96%) sepia(7%) saturate(2898%) hue-rotate(297deg) brightness(97%) contrast(86%);
}

.premium-package-logo-filtered-color {
    filter: brightness(100%) saturate(100%) invert(100%) sepia(100%) saturate(1%) hue-rotate(112deg) brightness(109%) contrast(101%);
}

.standard-package-logo-filtered-color {
    filter: brightness(0) saturate(100%) invert(35%) sepia(13%) saturate(822%) hue-rotate(66deg) brightness(95%) contrast(86%);
}

.package-section-contact-me-social-icon {
    height: 55px;
    width: 75px;
}

.package-section-contact-me-social-media-container {
    padding-top: 15px;
}

.price-value-button {
    border-radius: 10px;
    padding: 5px 10px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
    border: 2px solid;
    display: inline-flex;
    width: 150px;
    display: inline-flex;
    justify-content: center;
}

.price-value-button:hover {
    animation: price-hover 2s infinite;
}

.price-with-pulse-animation {
    animation: price-pulse 2s infinite;
}

@keyframes price-hover {
    0% {
        filter: contrast(100%);
    }

    50% {
        filter: contrast(150%);
    }

    100% {
        filter: contrast(100%);
    }
}

@keyframes price-pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
    }
}

.price-value {
    font-size: 30px;
    margin-bottom: 10px;
    border-bottom: 2px solid;
    padding-bottom: 10px;
}

.package-social-button-with-pulse-animation {
    animation: package-social-button-pulse 2s infinite;
}

@keyframes package-social-button-pulse {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.15);
    }

    100% {
        transform: scale(1);
    }
}