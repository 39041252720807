div.social-share-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 15px;
    gap: 15px;
}

.social-share-text {
    width: auto;
}

.copy-text {
    position: fixed;
    /* margin-left: 10px; */
    color: var(--verde);
    top: 45%;
    background-color: var(--rosa);
    padding: 10px;
    font-weight: bold;
    border-radius: 10px;
}

@media only screen and (max-width: 830px) {
    .social-share-text {
        width: 100px;
    }

    .social-share-container {
        justify-content: 'space-evenly';
        gap: 0px;
    }
}