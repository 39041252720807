.spinner {
    width: 50px;
    height: 50px;
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: var(--verde);
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: absolute;
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
}

.spinner-container {
    /* display: flex;
    justify-content: center; */
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}