.interesting-articles-section {
    /* border-bottom: 2px solid black; */
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 0px 150px;
}

@media only screen and (max-width: 1000px) {
    .interesting-articles-section {
        padding: 0px 30px;
    }
}

.interesting-articles-section-heading {
    font-size: var(--section-heading-font-size);
    color: var(--rosa);
    margin-bottom: 0px;
}