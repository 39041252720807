.random-blog-articles-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-bottom: 30px;
    align-items: center;
}

.random-blog-articles-container a {
    text-decoration: none;
}

.random-blog-article-container {
    border: 2px solid var(--rosa);
    position: relative;
    padding: 5px;
    /* padding-bottom: 0px !important; */
    margin: 0px 20px;
    width: 250px;
}

.random-blog-article-heading {
    color: var(--verde);
    /* position: absolute; */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(76, 98, 73, 0.8);
    /* Adjust the last value (alpha) to change transparency */
    color: white;
    /* Text color */
    text-align: center;
    font-weight: bold;
    padding: 5px 0px;
}

img.random-blog-article-image {
    width: 250px;
}

@media only screen and (max-width: 900px) {
    .random-blog-articles-container {
        flex-wrap: wrap;
    }

    .random-blog-article-container {
        width: 200px;
        margin: 10px;
    }

    img.random-blog-article-image {
        width: 200px;
    }
}

.random-blog-article-heading-text {
    padding: 0px 10px;
}