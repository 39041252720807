.expandedVipPackage {
    text-align: left;
    background-color: --var("rosa");
    padding: 5px 10px;
    /* display: flex;
    flex-direction: column;
    align-items: center; */
}

div .vipPackageHeading:first-child {
    margin-top: -25px !important;
    padding-top: 20px;
}

.aboveStandardAndPremiumPackage {
    margin-top: -10px !important;
}

div .vipPackageHeading:nth-child(2) {
    font-weight: bold;
    color: white;
    text-transform: uppercase;

    /* padding-bottom: 5px; */
}

.vipPackageHeading {
    font-weight: bold;
    color: white;
    text-transform: uppercase;
    padding-top: 10px;
    /* padding-bottom: 5px; */
    font-size: var(--package-item-heading-font-size);
}

.vipPackageHeadingIcon {
    font-size: var(--package-item-heading-icon-size);
    margin-bottom: -2px;
    margin-right: 5px;
}

.expandStandardPackageInVip {
    border: none;
    border-radius: 3px;
    padding: 5px 10px;
    cursor: pointer;
    margin: 10px 0px;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding-right: 15px;
}

.expandStandardPackageInVipContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.expandStandardPackageInVipIcon {
    margin-right: 5px;
}

.detailsStandard {
    /* max-height: 0; */
    margin-top: 5px;
    overflow: hidden;
    transition: height 0.6s ease-in;
}

.detailsStandard.open {
    /* height: auto; */
    /* margin-top: 0px; */
}

.detailsStandard.closed {
    /* height: 0; */
    /* margin-top: 5px; */
}