:root {
  --verde: #4C6249;
  --rosa: #E8B7B7;
  --rosa-claro: #FFEAEA;
  --section-heading-font-size: 30px;
  --package-item-heading-font-size: 13px;
  --package-item-text-font-size: 13px;
  --package-item-heading-icon-size: 25px;
  --translate-x-y-plus-value: 40%;
  --translate-x-y-minus-value: -40%;
  --anim-initial-opacity: 0.4;
  --anim-slide-duration: 0.7s;
}

.package-item-text {
  font-size: var(--package-item-text-font-size);
}

html {
  scroll-behavior: smooth;
}

h1,
h2 {
  white-space: normal;
  overflow: visible;
  word-wrap: break-word;
}

h2 {
  font-size: 26px !important;
}

body {
  margin: 0;
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}