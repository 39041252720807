.sliding-menu {
    position: fixed;
    top: 0;
    right: -250px;
    /* Hidden off-screen */
    width: 250px;
    height: 100%;
    background-color: #444;
    transition: right 0.3s ease;
}

.nav-section {
    /* margin-top: 90px; */
}

.social-container-for-menu-open a {
    padding-left: 10px;
}

.sliding-menu.open {
    right: 0;
    /* Slides in */
}

.close-button {
    color: white;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    margin: 10px;
}

.sliding-menu nav ul {
    list-style-type: none;
    padding: 0;
}

.sliding-menu nav ul li {
    margin: 15px 10px;
}

.sliding-menu nav ul li a {
    color: white;
    text-decoration: none;
    font-size: 18px;
}