.how-accompanying-heading {
    padding-top: 20px;
    margin-top: 20px;
    font-size: var(--section-heading-font-size);
    color: var(--verde)
}

.how-accompanying-heading-2 {
    color: white;
    margin-bottom: 10px;
    padding: 0px 15px;
}


.how-accompanying-sub-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 60vw;
    /* border-bottom: 1px solid var(--verde); */
    margin-bottom: 40px;
}

.how-accompanying-sub-section-number {
    position: relative;
    font-size: 40px;
    color: var(--verde);
}

.how-accompanying-sub-section-number.ha-number-left {
    left: -47px;
}

.how-accompanying-sub-section-number.ha-number-right {
    right: -47px;
}

.how-accompanying-sub-section:last-of-type {
    border-bottom: none;
}

.how-accompanying-sub-section img {
    height: 100px;
    display: none;
}

.how-accompanying-sub-section-text-block {

    color: var(--verde);
    font-size: 16px;
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    /* border: 3px solid white; */
    /* background-color: var(--verde); */
    text-align: justify;
}

.how-accompanying-sub-section-icon {
    align-self: center;
    font-size: 40px;
    margin-bottom: 15px;
}

.howAccompanyingWorksSection {
    /* border-bottom: 2px solid black; */
    /* display: flex;
    flex-direction: column; */
    background-color: white;
    /* align-items: center; */
    padding: 0px 150px;
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .howAccompanyingWorksSection {
        padding: 0px;
        padding-bottom: 30px;
    }

    .how-accompanying-sub-section {
        width: 70vw;
    }

    .ha-border-left .how-accompanying-sub-section-text-block {
        text-align: left;
    }

    .ha-border-right .how-accompanying-sub-section-text-block {
        text-align: right;
    }
}

.how-accompanying-contact-me {
    background-color: #FFEAEA;
    width: 150px;
    color: #4C6249;
    font-weight: bold;
    border-radius: 10px;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 10px 15px;
    cursor: pointer;
}

.how-accompanying-sub-section.ha-border-left {
    border-left: 1px solid var(--verde);
}

.how-accompanying-sub-section.ha-border-right {
    border-right: 1px solid var(--verde);
}