.top-about-brief-section {
    background-image: url('./../../assets/landingPage/section_1.jpg');
    background-repeat: no-repeat;
    background-size: cover
}

.site-main-heading {
    padding-top: 30px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    color: var(--verde);
    font-size: 30px;
    margin-top: 0px;
}

.top-about-form-container {
    background: rgba(255, 234, 234, 0.6);
    color: var(--verde);
    text-align: left;
    padding-left: 200px;
    clip-path: polygon(0 0, 100% 0, 100% 30%, 100% 70%, 80% 100%, 20% 100%, 0% 70%, 0% 30%);
    padding-bottom: 50px;
}

.top-section-contact-form-container {
    position: relative;
    top: -40px;
}

.top-about-form-container p {
    font-weight: 600;
    border-left: 3px solid var(--verde);
    padding-left: 10px;
}


.top-about-form-container li {
    font-weight: 500;
    list-style: none;
    font-size: 16px;
}

.premium-list-item-container {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: 0;
    margin-top: 0px;
}

.premium-list-item {
    display: inline-flex;
    align-items: center;
    margin: 7px 0;
    padding: 10px;
    border-radius: 8px;
    background: linear-gradient(135deg, white, var(--rosa-claro));
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 18px;
    color: var(--verde);
}

.premium-list-item .bullet {
    display: inline-block;
    /* width: 20px;
    height: 20px; */
    padding: 0 7px;
    margin-right: 10px;
    /* border-radius: 50%; */
    background: linear-gradient(135deg, #ffafbd, #ffc3a0);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    color: white;
    text-align: center;
    line-height: 20px;
    font-weight: bold;
    font-size: 14px;
}

.premium-list-item .bullet-text {
    /* flex-grow: 1; */
}

@media only screen and (max-width: 600px) {
    .top-about-form-container {
        padding: 0px 10px;
        clip-path: none;
    }

    .top-section-contact-form-container {
        top: 0;
    }

    .site-main-heading {
        text-align: center;
    }

    .top-about-form-container p {
        text-align: center;
    }
}