.blogArticlePreview {
    max-width: 340px;
    min-width: 200px;
    margin: 10px 10px;
    /* background-color: var(--rosa); */
    align-self: flex-start;
    /* border: 3px solid var(--verde); */
    text-align: left;
    padding: 10px;
    border-radius: 15px;
}

.blogArticlePreview:hover {
    background-color: var(--rosa-claro);
    transition: background-color 0.3s ease-in-out;
}

.blogArticlePreview:not(:hover) {
    background-color: none;
    transition: background-color 0.3s ease-in-out;
}

.blogArticlePreview a {
    text-decoration: none;
}

.blogArticlePreview h3 {
    margin-top: 5px;
    color: var(--verde);
    margin-bottom: 0px;
    font-size: 24px;
    font-weight: 500;
}

.article-preview-read-more {
    text-decoration: underline;
    text-transform: uppercase;
}

.article-date-published {
    margin-bottom: 0px;
    font-size: 12px !important;
}

.blogArticlePreview p {
    color: var(--verde);
    font-size: 14px;
}

.blogArticlePreview img {
    max-width: 340px;
    min-width: 200px;
    object-fit: cover;
    border-radius: 15px;

}