.map-section-container {
    background-color: var(--rosa-claro);
    padding: 30px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.map-section-heading-text {
    color: var(--verde);
    font-size: var(--section-heading-font-size);
    padding: 0px 15px !important;
}

.map-button-container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.map-country-button {
    margin: 5px 5px;
    /* border: 2px solid #4C6249; */
    /* background-color: #E8B7B7 !important; */
    color: white;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
}

figure.worldmap__figure-container {
    background-color: var(--rosa-claro) !important;
    margin: 0px !important;

}

.react-transform-wrapper {
    border: 2px solid var(--rosa) !important;
}

.zoom-button-container {
    display: flex;
    flex-direction: row;
    /* align-self: flex-end; */
    margin-right: 10px;
    margin-top: 5px;
}

.map-zoom-button {
    font-size: 30px;
    margin: 0px 5px;
    color: var(--verde);
    cursor: pointer;
}