.our-clients-image {
    height: 350px;
}

.react-multi-carousel-track {
    margin-bottom: 50px;
}

.react-multiple-carousel__arrow {
    z-index: 4;
}

.react-multi-carousel-dot-list {
    flex-wrap: wrap;
}