.question-answer-section {
    /* border-bottom: 2px solid black; */
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    background-color: var(--rosa-claro);
    align-items: center;
}

.question-answer-section-heading-text {
    color: var(--verde);
    font-size: var(--section-heading-font-size);
    padding: 0 20px;
}

.question-answer-section-subheading-text {
    padding: 0 20px;
}

.question {
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    margin-bottom: 20px;
    overflow: hidden;
    max-width: 600px;
    text-align: left;
}

.question-header {
    background-color: var(--verde);
    padding: 10px;
    cursor: pointer;
    color: var(--rosa-claro);
    display: flex;
    align-items: center;
    padding: 10px;
}

.answer {
    padding: 10px;
    background-color: white;
    color: var(--verde);
    /* border-top: 1px solid #ccc; */
    /* animation: slideIn 0.5s ease-in; */
    padding: 15px;
    transform: translateY(0);
    max-width: 600px;
}

.question-icon {
    font-size: 30px;
    margin-right: 10px;
}

@media only screen and (max-width: 600px) {
    .question {
        width: 300px;
    }
}