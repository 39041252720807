.fullBlogArticle {
    /* width: 100%; */
    padding: 0px 120px;
}

.fullBlogArticle h1 {
    font-size: 7vh;
    padding: 0px 20px;
    font-weight: 500;
    /* margin-bottom: 10px; */
    color: var(--rosa-claro);
}

.fullBlogArticle img.blog-image {
    max-width: 100%;
    min-width: 200px;
    filter: brightness(55%);
    border-radius: 15px;
}

.fullArticleText {
    margin: 30px 55px;
}

.fullArticleText p {
    text-align: left;
    line-height: 1.95;
    font-size: 14px;
}


.fullArticleText p div.more-info-button-container {
    text-align: center;
}

.back-button a {
    text-decoration: none;
    font-size: 13px;
    color: var(--verde);
    font-weight: bold;
    display: flex;
    align-items: center;
}

.back-button {
    border: 2px solid var(--verde);
    padding: 5px 10px 5px 5px;
    margin: 5px;
    /* background-color: var(--rosa); */

}

.back-button-arrow-icon {
    font-size: 18px;
    margin-right: 5px;
}

.back-button-arrow-icon:nth-child(2) {
    margin-right: 10px;
}

.back-buttons-container {
    padding-top: 30px;
    display: flex;
    flex-direction: row;
}

.article-end-back-buttons-container {
    padding-top: 0px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: row;
}

.other-blog-articles-text {
    font-size: 20px;
    font-weight: bold;
    color: var(--rosa);
    margin-bottom: 20px;
    margin-top: 20px;
}

div.date-published {
    font-size: 3vh;
    margin-top: 20px;
    color: white
}

@media only screen and (max-width: 830px) {
    .fullArticleText {
        margin: 30px 35px;
    }
}

.fullBlogArticle ol,
.fullBlogArticle li {
    text-align: left;
    line-height: 1.95;
    font-size: 14px;
}

.site-name-in-blog {
    font-size: 4vh !important;
    /* margin-top: -10px; */
    font-weight: normal;
    color: #f3f1f1;
}

.fullArticleText img.img-height-300 {
    height: 300px;
}

.full-blog-header-container {
    position: relative;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-blog-header-container .slide-in-up {
    /* background-color: lightgrey;
    border-radius: 15px; */
}

.full-blog-header-container .slide-in-up .spinner-container {
    width: 100%
}

.full-blog-header-text-container {
    color: white;
    max-width: 780px;
    min-width: 220px;
    position: absolute;
}

.full-blog-header-breadcrumb {
    display: flex;
    flex-direction: row;
    position: absolute;
    top: 25px;
    left: 65px;
    color: black;
    width: 100%;
}

@media only screen and (max-width: 830px) {
    .full-blog-header-breadcrumb {
        display: none;
    }

    .full-blog-header-container {
        padding-top: 20px;
    }

    .fullBlogArticle {
        padding: 10px;
    }
}

.full-blog-header-breadcrumb a {
    color: black;
    text-decoration: none;
    margin-right: 10px;
    margin-left: 10px;
}

.full-blog-header-breadcrumb-current-title {
    margin-right: 10px;
    margin-left: 10px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

@media only screen and (max-width: 600px) {
    .fullBlogArticle h1 {
        font-size: 4.1vh;
    }

    .site-name-in-blog {
        font-size: 3.1vh !important;
    }

    div.date-published {
        font-size: 2vh;
    }
}

@media only screen and (max-width: 450px) {
    .fullBlogArticle h1 {
        font-size: 3.5vh;
    }

    .site-name-in-blog {
        font-size: 2.5vh !important;
    }

    div.date-published {
        font-size: 2vh;
    }
}

@media only screen and (min-height: 750px) {
    .fullBlogArticle h1 {
        font-size: 20px;
    }

    .site-name-in-blog {
        font-size: 16px !important;
    }

    div.date-published {
        font-size: 15px;
    }
}