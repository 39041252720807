.expandedStandardPackage {
    text-align: left;
    background-color: #FFEAEA;
    padding: 5px 10px
}


div .standardPackageHeading:first-child {
    font-weight: bold;
    color: #4C6249;
    text-transform: uppercase;
    margin-top: -25px !important;
    padding-top: 20px;
    /* padding-bottom: 5px; */
}

.standardPackageHeading {
    font-weight: bold;
    color: #4C6249;
    text-transform: uppercase;
    padding-top: 10px;
    /* padding-bottom: 5px; */
    font-size: var(--package-item-heading-font-size);
}

.standardPackageHeadingIcon {
    font-size: var(--package-item-heading-icon-size);
    margin-bottom: -2px;
    margin-right: 5px;
}

.premiumPackagePlusIcon {
    font-size: 50px;
    color: #FFEAEA;
    margin-top: 10px;
    margin-bottom: -40px;
}

.premiumPackageWithinFullPlusIcon {
    font-size: 50px;
    color: #74acdf;
    margin-top: 10px;
    margin-bottom: -40px;
}

.fullPackagePlusIcon {
    font-size: 50px;
    color: '#74acdf';
    margin-top: 10px;
    margin-bottom: -0px;
}

.plusIconContainer {
    display: flex;
    justify-content: center;
}