.floating-button-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.floating-button {
    padding: 15px;
    border-radius: 50px;
    background-color: #4C6249;
    color: #E8B7B7;
    font-size: 60px;
    border: none;
    cursor: pointer;
}

.with-pulse-animation {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 15px rgba(0, 0, 0, 0);
    }
}

.no-pulse-animation-only-shadow {
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
}

.expanded-elements {
    display: flex;
    flex-direction: column;
    animation: slideIn 0.5s ease forwards;
    /* Animation for elements sliding in */
    opacity: 0;
    /* Start with opacity 0 */
}

@keyframes slideIn {
    from {
        transform: translateY(200%);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.floating-element {
    opacity: 0;
    /* Initially hidden */
    animation: fadeIn 0.5s ease forwards;
    /* Animation for elements fading in */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.whatsappContactFloating,
.instagramContactFloating,
.telegramContactFloating {
    height: 70px;
    width: 60px;
    /* margin: 10px; */
    /* Adjust spacing between elements */
}

.floating-element-text:hover::after {
    content: attr(data-text);
    position: absolute;
    /* top: 50%; */
    right: calc(100% - 5px);
    transform: translateY(30%);
    padding: 10px;
    background-color: #4C6249;
    color: #FFEAEA;
    border-radius: 5px;
    font-size: 16px;
    white-space: nowrap;
    opacity: 0;
    animation: fadeInText 0.3s ease forwards;
}

/* Animation for the text appearing */
@keyframes fadeInText {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

/* Styling for the icon shadow */
.floating-element:hover {
    filter: drop-shadow(0 0 5px rgba(0, 0, 0, 0.3));
    /* Add shadow on hover */
    transition: filter 0.3s ease;
    /* Animation for shadow */
}