.tooltip {
    position: relative;
    /* left: 50%; */
    transform: translateY(135%);
    padding: 10px;
    background-color: var(--verde);
    color: #fff;
    border-radius: 4px;
    border: 2px solid var(--rosa);
    /* display: hidden; */
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    z-index: 3;
    margin-top: -40px;
}

.tooltip.visible {

    opacity: 1;
}