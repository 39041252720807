/* .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */

/*my code begins here*/



.topNav {
  width: 100%;
  position: fixed;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #4C6249;
  /* padding: 10px 0px; */
  top: 0;
  z-index: 5;
  flex-direction: row;
  flex-wrap: nowrap;
  /* border-bottom: 2px solid var(--rosa-claro); */
}

.topNavMobile {
  justify-content: space-between !important;
}

.centerNav {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  padding-left: 0px;
}

.centerNav li {
  /* Optionally, you can apply additional styles to center the list items within the centerNav */
  display: inline-block;
  margin: 0 10px;
  /* Adjust spacing between items as needed */
}

nav ul {
  list-style: none;
  font-size: 18px;
}

nav ul li {
  /* border-bottom: 3px solid #E8B7B7; */
}

nav a {
  text-decoration: none;
  color: var(--rosa-claro);
  padding-bottom: 5px;
  font-weight: bold;
  transition: color 0.3s ease;
  font-size: 15px;
  position: relative;
  /* Smooth color transition */
}

nav li a::before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 50%;
  background-color: white;
  /* Border color */
  transition: width 0.4s ease, left 0.4s ease;
}

nav li a:hover::before {
  width: 100%;
  left: 0;
}

nav a:hover {
  color: white;
}

/* Responsive Styles */
@media only screen and (max-width: 965px) {


  .centerNav {
    align-items: flex-start;
    margin: 10px;
  }

  nav ul {
    flex-direction: column;
    align-items: center;
  }

  nav li {
    margin-right: 0;
    margin-bottom: 10px;
    /* Add space between items */
  }

  .rightNav {
    margin-top: 0px;
  }

  .centerNav li {
    margin: 0px;
    margin-bottom: 5px;
  }
}

.siteNameButton {
  list-style: none;
}

.siteNameButton a {
  text-decoration: none;
  color: #E8B7B7;
  font-size: 30px;
  /* color: white */
}

.siteNameButton a:hover {
  color: #FFEAEA;
}

.leftNav {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 30px;
  /* position: absolute; */
  /* top: 0; */
  /* margin-left: 55px; */
  /* margin-top: 12px; */
  align-items: center;
  position: absolute;
  left: 0px;
  top: 0px;
  background-color: var(--verde);
  border-radius: 100px;
  padding: 0px 10px;
  border-bottom-left-radius: 0px;
  border-top-left-radius: 0px;
}

.leftNav li {
  border: 3px solid;
  padding: 5px;
  color: #E8B7B7
}

.leftNav li:hover {
  border: 3px solid;
  padding: 5px;
  color: #FFEAEA;
}

.siteLogo {
  /* border: 5px solid; */
  /* color: white; */
  color: #E8B7B7;
  /* background-color: grey; */
  /* margin-left: 20px; */
  padding: 0px 10px;
  /* height: 50px; */
  font-size: 55px;
}

.App {
  text-align: center;
  margin-top: 60px;
}

@media only screen and (max-width: 1037px) {
  .App {
    margin-top: 45px;
  }

  .rightNav {
    margin-left: 0px !important;
  }
}

@media only screen and (max-width: 1167px) {
  .App {
    margin-top: 45px;
  }

  .topNav {
    justify-content: flex-end;
  }

  .burger-menu-social-links-container {
    margin-right: 10px;
  }
}

@media only screen and (max-width: 830px) {
  /* .App {
    margin-top: 0px;
  } */

  .topNav {
    justify-content: flex-end;
  }

  .burger-menu-social-links-container {
    margin-right: 10px;
  }
}

.siteTagLine {
  font-size: 15px;
  color: #FFEAEA
}

.topSvgLogo {
  fill: #E8B7B7;
  font-size: 30px;
  margin-left: 10px;
  cursor: pointer;
  padding-top: 5px;
}

.topSvgLogo:hover {
  fill: #FFEAEA;
}

.topTelegramLogo {
  margin-left: 7px;
}

.rightNav {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 30px;
  /* position: absolute; */
  top: 0;
  right: 0;
  /* margin-top: 25px; */
  /* margin-right: 15px; */
  align-items: center;
  margin-left: 30px;
}

@media only screen and (max-width: 600px) {

  .App {
    position: relative;
    margin-top: 120px;
  }

  /* .burger-menu-social-links-container {
    margin-bottom: 20px;
  } */

  .leftNav {
    position: relative;
    left: 0px;
    top: 0px;
    background-color: var(--verde);
    border-radius: 100px;
    padding: 0px 10px;
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }

  .leftNav svg {
    width: 120px !important;
    margin: -15px 0px;
  }


}

/* this used to be (max-width: 970px) */
@media only screen and (max-width: 970px) {
  .topNav {
    justify-content: space-evenly;
    flex-direction: row;
    padding-top: 0px;
  }


  /* .leftNav svg {
    width: 120px !important
  } */

  .rightNav {
    margin-left: 0px;
  }
}

.burger-menu-button {
  color: var(--rosa);
  cursor: pointer;
  transition: color 0.3s ease;
}

.burger-menu-button:hover {
  color: white;
}

.burger-menu-button-open {
  padding: 10px 0px;
  font-size: 40px;
}

.burger-menu-button-closed {
  /* position: absolute; */
  font-size: 25px;
  /* top: 10px; */
  /* transform: rotate(90deg); */
  /* margin-top: 10px; */
  /* bottom: -55px; */
  color: var(--rosa);
  /* right: 42px; */
  border: 2px solid var(--rosa);
  /* background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px; */

}

.burger-menu-social-links-container {
  display: flex;
}

.center-nav-sub-nav {
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
}

.svg-element .s0 {
  fill: var(--rosa) !important;
  transition: fill 0.1s linear;
}

.svg-element:hover .s0 {
  fill: var(--rosa) !important;
}

.svg-element:active .s0 {
  fill: white !important;
  /* Color on click (active) */
}